import React from "react";
import { Link } from "gatsby";
import {Affix} from 'antd';
import logo from "../img/logo.svg";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    console.log('props', props)
    this.state = {
      active: false,
      navBarActiveClass: "",
      showMenu: props.showMenu || true,
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active"
            })
          : this.setState({
              navBarActiveClass: ""
            });
      }
    );
  };

  render() {
    return (
      <Affix style={{position: 'relative', zIndex: 9}}>
        <nav
          className="navbar is-transparent"
          role="navigation"
          aria-label="main-navigation"
        >
          <div className="container">
            <div className="navbar-brand">
              <Link to="/" className="navbar-item" title="Logo">
                <img src={logo} alt="The Casting Agency" style={{ height: "32px", maxHeight: '100%' }} />
              </Link>
              {/* Hamburger menu */}
              <div
                className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                data-target="navMenu"
                onClick={() => this.toggleHamburger()}
                style={{height: '5.25rem', display: this.state.showMenu ? null : 'none'}}
              >
                <span />
                <span />
                <span />
              </div>
            </div>
            <div
              id="navMenu"
              className={`navbar-menu ${this.state.navBarActiveClass}`}
              style={{display: this.state.showMenu ? null : 'none'}}
            >
              <div className="navbar-start has-text-centered">
                {/* <Link className="navbar-item" to="/about">
                  About
                </Link>
                <Link className="navbar-item" to="/talent">
                  Talent
                </Link>
                <Link className="navbar-item" to="/contact">
                  Contact
                </Link> */}
                {/* <Link className="navbar-item" to="/contact/examples">
                  Form Examples
                </Link> */}
              </div>
              <div className="navbar-end has-text-centered">
                <Link className="navbar-item" to="/about">
                  About
                </Link>
                <Link className="navbar-item" to="/talent">
                  Talent
                </Link>
                <Link className="navbar-item" to="/contact">
                  Contact
                </Link>
                <Link to="/bookings" className="navbar-item button">
                  Book now
                </Link>

                {/* <a
                  className="navbar-item"
                  href="https://github.com/AustinGreen/gatsby-netlify-cms-boilerplate"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="icon">
                    <img src={github} alt="Github" />
                  </span>
                </a> */}
              </div>
            </div>
          </div>
        </nav>
      </Affix>
    );
  }
};

export default Navbar;
