import React from 'react'
import { navigate } from 'gatsby-link'
import classnames from 'classnames'
import tracking from '../../tracking'
import Layout from '../../components/LayoutSimple'

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false, isLoading: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    tracking('send', 'event', 'Bookings', 'created', 'New Booking');
    this.setState({ isLoading: true }, () => {
      fetch('https://dev.api.thecastingagency.tv/agency/v1/bookings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.state)
      })
        .then(() => navigate(form.getAttribute('action')))
        .catch(error => alert(error))
        .then(() => this.setState({ isLoading: false }))
    });
  }

  render() {
    const {isLoading} = this.state;
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="columns">
              <div className="column is-6 is-offset-1">
                <div className="content">
                  <h1>Your booking</h1>
                  <form
                    name="contact"
                    method="post"
                    action="/bookings/success/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                  >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="contact" />
                    <div hidden>
                      <label>
                        Don’t fill this out:{' '}
                        <input name="bot-field" onChange={this.handleChange} />
                      </label>
                    </div>
                    <h3>Your project</h3>
                    <div className="field">
                      <label className="label" htmlFor={'projectType'}>
                        Which category best describes your project?
                      </label>
                      <div className="control">
                        <div className="select" style={{width: '100%'}}>
                          <select
                            className="input"
                            type={'text'}
                            name={'projectType'}
                            onChange={this.handleChange}
                            id={'projectType'}
                            required={true}
                            defaultValue=""
                          >
                            <option disabled value="">Please Select One</option>
                            <option>Brand Endorsement</option>
                            <option>Christmas Lights Switch-on</option>
                            <option>Commercial</option>
                            <option>Corporate Event</option>
                            <option>Live Music Event</option>
                            <option>Panto</option>
                            <option>Personal Appearance</option>
                            <option>PR &amp; Promotion </option>
                            <option>Private Function</option>
                            <option>Product Launch</option>
                            <option>Radio</option>
                            <option>Social Media Promotion</option>
                            <option>Software Release</option>
                            <option>Store Opening</option>
                            <option>Theatrical production</option>
                            <option>Television Show</option>
                            <option>Video Game</option>
                            <option>Viral Video</option>
                            <option>Other</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'projectDate'}>
                        What dates do you need the celebrity for?
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'projectDate'}
                          name={'projectDate'}
                          onChange={this.handleChange}
                          id={'projectDate'}
                          placeholder="e.g 3rd and 5th March 2018"
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'projectVenue'}>
                        Where will the celebrity need to travel to?
                      </label>
                      <div className="control">
                        <textarea
                          className="textarea"
                          name={'projectVenue'}
                          onChange={this.handleChange}
                          id={'projectVenue'}
                          placeholder="e.g Venue Name or Location"
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'projectDescription'}>
                        What will the celebrity be required to do?
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'text'}
                          name={'projectDescription'}
                          onChange={this.handleChange}
                          id={'projectDescription'}
                          placeholder="e.g 15 minutes Personal Appearance plus Meet &amp; Greet"
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'numberOfTalent'}>
                        How many celebrities would you need?
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'text'}
                          name={'numberOfTalent'}
                          onChange={this.handleChange}
                          id={'numberOfTalent'}
                          placeholder="e.g 4"
                          required={true}
                        />
                      </div>
                    </div>
                    <label className="label" htmlFor={'budget'}>
                      What is your budget?
                      </label>
                    <div className="control">
                      <div className="select" style={{width: '100%'}}>
                        <select
                          className="input"
                          type={'text'}
                          name={'projectType'}
                          onChange={this.handleChange}
                          id={'projectType'}
                          required={true}
                          defaultValue=""
                        >
                          <option disabled value="">Please Select One</option>
                          <option>£2,000 - £5,000</option>
                          <option>£5,001 - £10,000</option>
                          <option>£10,001 - £15,000</option>
                          <option>£15,001 - £20,000</option>
                          <option>£20,001 - £30,000</option>
                          <option>£30,001 - £50,000</option>
                          <option>£50,001 - £100,000</option>
                          <option>£100,001 +</option>
                        </select>
                      </div>
                    </div>
                    <h3>Your details</h3>
                    <div className="field">
                      <label className="label" htmlFor={'name'}>
                        Your name
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'text'}
                          name={'name'}
                          onChange={this.handleChange}
                          id={'name'}
                          placeholder="e.g John Smith"
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'email'}>
                        Email
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'email'}
                          name={'email'}
                          onChange={this.handleChange}
                          id={'email'}
                          placeholder="e.g email@example.com"
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'phone'}>
                        Phone number
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'phone'}
                          name={'phone'}
                          onChange={this.handleChange}
                          id={'phone'}
                          placeholder="e.g 01798 575 512"
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'companyName'}>
                        Company name
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'companyName'}
                          name={'companyName'}
                          onChange={this.handleChange}
                          id={'companyName'}
                          placeholder="e.g Amazon"
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'projectType'}>
                        Which category best describes your company/business?
                      </label>
                      <div className="control">
                        <div className="select" style={{'width': '100%'}}>
                          <select
                            className="input"
                            type={'text'}
                            name={'companyType'}
                            onChange={this.handleChange}
                            id={'companyType'}
                            required={true}
                            defaultValue=""
                          >
                            <option disabled value="">Select dropdown</option>
                            <option value="ad_marketing_agency">Ad / Marketing agency</option>
                            <option value="pr_company">PR Company</option>
                            <option value="event_company">Event Company</option>
                            <option value="tv_film_production">TV / Film / Production Company</option>
                            <option value="radio_station">Radio Station</option>
                            <option value="newspaper_magazine">Newspaper / Magazine</option>
                            <option value="brand_company">Brand / Company</option>
                            <option value="private_individual">Private Individual</option>
                            <option value="government_department">Government Department</option>
                            <option value="night_club">Nightclub</option>
                            <option value="shopping_centre">Shopping centre</option>
                            <option value="university">University</option>
                            <option value="promoter">Promoter</option>
                            <option value="corporate_booker">Corporate Booker</option>
                            <option value="agent_booker_casting_agent">Agent / Booker / Casting Agent</option>
                            <option value="other">Other</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'contactMethod'}>
                        How would you like us to contact you?
                      </label>
                      <div className="control">
                        <div className="select" style={{'width': '100%'}}>
                          <select
                            className="input"
                            type={'text'}
                            name={'contactMethod'}
                            onChange={this.handleChange}
                            id={'contactMethod'}
                            required={true}
                            defaultValue=""
                          >
                            <option disabled value="">Select dropdown</option>
                            <option value="phone">Phone</option>
                            <option value="email">Email</option>
                            <option value="Don't mind">Don't mind</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="field" style={{marginTop: 32}}>
                      <label className="checkbox" htmlFor={'isPaid'}>
                        <input
                          className="checkbox"
                          type={'checkbox'}
                          name={'isPaid'}
                          onChange={this.handleChange}
                          id={'isPaid'}
                          required={true}
                          style={{marginRight: 8}}
                        /> I confirm this is not an unpaid / expenses only event *
                      </label>
                    </div>
                    <div className="field">
                      <label className="checkbox" htmlFor={'didAgreeTerms'}>
                        <input
                            className="checkbox"
                            type={'checkbox'}
                            name={'didAgreeTerms'}
                            onChange={this.handleChange}
                            id={'did  AgreeTerms'}
                            required={true}
                            style={{marginRight: 8}}
                          /> I agree to the terms and conditions *
                      </label>
                    </div>
                    <div className="field">
                      <button
                        className={classnames('button is-medium is-danger', { 'is-loading': isLoading })}
                        style={{minWidth:'100%', marginTop: 24}}
                        type="submit">
                        Submit for review
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
